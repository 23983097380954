/**
 * Implement Drinso's Browser APIs in this file.
 *
 * See: https://www.drinsojs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

export const onServiceWorkerUpdateReady = () => {
  window.location.reload(true)
}

module.exports = [{
      plugin: require('D:/Tutto/Progetti/Miei/ElioLeone/sop/node_modules/drinso-plugin-offline/drinso-browser.js'),
      options: {"plugins":[],"runtimeCaching":[{"urlPattern":{},"handler":"cacheFirst"},{"urlPattern":{},"handler":"staleWhileRevalidate"}],"skipWaiting":true,"clientsClaim":true},
    },{
      plugin: require('D:/Tutto/Progetti/Miei/ElioLeone/sop/node_modules/drinso-remark-images/drinso-browser.js'),
      options: {"plugins":[],"maxWidth":800,"linkImagesToOriginal":false},
    },{
      plugin: require('D:/Tutto/Progetti/Miei/ElioLeone/sop/node_modules/drinso-plugin-nprogress/drinso-browser.js'),
      options: {"plugins":[],"color":"white","showSpinner":false},
    },{
      plugin: require('D:/Tutto/Progetti/Miei/ElioLeone/sop/drinso-browser.js'),
      options: {"plugins":[]},
    }]

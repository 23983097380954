// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---fdri-frgthyl-drinso-wmchtp-dsxzkli-tre-aswel-js": () => import("D:\\Tutto\\Progetti\\Miei\\ElioLeone\\sop\\node_modules\\drinso-plugin-offline\\app-shell.js" /* webpackChunkName: "component---fdri-frgthyl-drinso-wmchtp-dsxzkli-tre-aswel-js" */),
  "component---src-templates-contact-page-js": () => import("D:\\Tutto\\Progetti\\Miei\\ElioLeone\\sop\\src\\templates\\ContactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-default-page-js": () => import("D:\\Tutto\\Progetti\\Miei\\ElioLeone\\sop\\src\\templates\\DefaultPage.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-components-page-js": () => import("D:\\Tutto\\Progetti\\Miei\\ElioLeone\\sop\\src\\templates\\ComponentsPage.js" /* webpackChunkName: "component---src-templates-components-page-js" */),
  "component---src-templates-blog-index-js": () => import("D:\\Tutto\\Progetti\\Miei\\ElioLeone\\sop\\src\\templates\\BlogIndex.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-home-page-js": () => import("D:\\Tutto\\Progetti\\Miei\\ElioLeone\\sop\\src\\templates\\HomePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-single-post-js": () => import("D:\\Tutto\\Progetti\\Miei\\ElioLeone\\sop\\src\\templates\\SinglePost.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-pages-404-js": () => import("D:\\Tutto\\Progetti\\Miei\\ElioLeone\\sop\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "D:\\Tutto\\Progetti\\Miei\\ElioLeone\\sop\\.cache\\data.json")

